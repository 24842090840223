import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
// import { Redirect, Route } from "react-router";
import { AuthContext } from "../../context/AuthContext";

export const PrivateRoute = ({children, path}) =>{

    const {token} = useContext(AuthContext);
    
    if(!token){
        return <Navigate to="/login" />
    }

    return (
            children
    )
}