import "./TicTokClone.css";
import React from 'react'
import Video from "./Video/Video";

function TicTokClone() {
  const arr = [
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/274300791_481339383545082_8841239214318047869_n.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/Good+morning++%23explore+%23explorepage+%23reelitfeelit+%23reelkarofeelkaro+%23sakshi.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/Follow+%40FUCKGIRLPROBLEM.S+for+more+cute+videos+-(credit+%40sabrinaliuu).mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/AirPod++you%E2%80%99re+not+wrong+tho%23rachelpizzolato+%23reels+%23beautyandbrainswithatwist+%23airpods+%23p.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/283658881_1600872480293106_7223396284303290547_n.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/281749033_553345526362760_3904971331567365208_n.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/280916028_972486193435558_2610641842748295475_n.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/279721426_536627864542663_5379571972737883868_n.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/278041148_4708779835887960_3920310288900822863_n.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/277892315_671315817308293_3874122884049529941_n.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/277603955_864351908296154_2207550652033823394_n.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/instagram+-+2774641965751466153+-+navharyanvi+-+CaBgirLgfip.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/instagram+-+2846703866862225585+-+nandanisharma512+-+CeBhhWdDcix.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/yes+made+one+more+video+on+this+song+cause+why+not+++%23AnkitaChhetri+%23Pasoori.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/instagram+-+2810137612620867886+-+rawdy_megha__official_fp+-+Cb_nUUaOyku.mp4",
    "https://s3.ap-south-1.amazonaws.com/tik-tok.com/videos/instagram+-+2822833839286672685+-+batuk_baba__+-+CcsuGu6ol0t.mp4"
  ];

  return (
    <div className="project-wrapper">
      <div className='app__videos'>
        {
          arr.map((linka) => (
            <Video 
              videoUrl={linka}
            />
          ))
        }
      </div>
    </div>
  )
}

export default TicTokClone