import React, { useState } from 'react';

function QuestionComponent({ questionData, handleChange }) {
  const [selectedAnswer, setSelectedAnswer] = useState("");

  const question = questionData.Name;
  const answers = questionData.Answers__r.records;

  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);

    const selectedOption = event.target.value;
    const selectedAnswerData = answers.find((answer) => answer.Name === selectedOption);


    const ObjAns = {
        Question: questionData.Name,
        QuestionId: questionData.Id,
        Answer: selectedAnswerData.Name,
        AnswerId: selectedAnswerData.Id
    }

    handleChange(ObjAns);

  };



  return (
    <div>
      {/* <h2>Question:</h2> */}
      <p> <span>*</span> {question}</p>

      {/* <h2>Answers:</h2> */}
      <select value={selectedAnswer} onChange={handleAnswerChange} style={{marginLeft: '10px'}}>
        <option value="" key="" >Select an answer</option>
        {answers.map((answer) => (
          <option key={answer.Id} value={answer.Name}>
            {answer.Name}
          </option>
        ))}
      </select>

      {/* {selectedAnswer && (
        <p>You selected: {selectedAnswer}</p>
      )} */}
      <br /><br />



    </div>
  );
}

export default QuestionComponent;
