import { Link } from "react-router-dom";

export const Navbar = () => {
    return (
        <div style={{
            display:"flex",
            gap:"30px",
            width:"500px",
            margin:"auto",
            marginTop:"50px",
            color:"white",
            background:"white"
        }}>
            <Link to="/">Home</Link>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/settings">Settings</Link>
            <Link to="/login">Login</Link>
            <Link to="/mydata">My Data</Link>
        </div>
    );
};