import React, { useEffect, useState } from 'react';
import "./PratikChandore.css"
import Footer from '../Footer/Footer';
import QuestionComponent from '../QuestionComponent/QuestionComponent';

function PratikChandore() {

    const [data, setData] = useState([]);

    const [submitData, setSubmitData] = useState([]);

    const handleChange = (currObj) => {
        setSubmitData([...submitData, currObj])
    }

    useEffect(() => {
        // Get the URL search parameters
        const urlSearchParams = new URLSearchParams(window.location.search);
    
        // Get the 'id' parameter from the URL
        const id = urlSearchParams.get('id');
    
        if (id) {
          getData(id);
        }
    }, []);

    function getData(id) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer 00D5i000008y244!ARoAQOd4CjpdVIUjqpA.hSWX9sBfuRDlF00AaAFqKGAx6WEsGV4EVGRfn5F7hRM_zAGtyi5ss26BSG5.kJnwjhxtasZMYChv");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "BrowserId=L0MZp0o2Ee60ICc7V3OMDw; CookieConsentPolicy=0:1; LSKey-c$CookieConsentPolicy=0:1");
        
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        // 0015i00000Lzx0iAAB
        fetch(
          `https://cunning-badger-h123en-dev-ed.my.salesforce.com/services/apexrest/accountRecord/${id}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => setData(result))
          .catch((error) => console.log("error", error));
    }

    const renderQAndA = (QandAData) => {
        return (
            <>
                {QandAData.map((qandA, index) => {
                    return(
                        <QuestionComponent 
                        questionData={qandA}
                        key={index}
                        handleChange={handleChange}
                    />
                    )
                })}
            </>
        );
    }

    const handleSubmit = () => {

        const urlSearchParams = new URLSearchParams(window.location.search);
    
        // Get the 'id' parameter from the URL
        const id = urlSearchParams.get('id');


        var raw = JSON.stringify(submitData);
        // console.log('checking=>', 'fdsfd8076jhiu=====>>>', raw);

        // return;

        submitData.map((subData) => {

            if (id) {
    
                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer 00D5i000008y244!ARoAQOd4CjpdVIUjqpA.hSWX9sBfuRDlF00AaAFqKGAx6WEsGV4EVGRfn5F7hRM_zAGtyi5ss26BSG5.kJnwjhxtasZMYChv");
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Cookie", "BrowserId=st_7B0pWEe6bTMNr1kcUpg; CookieConsentPolicy=0:1; LSKey-c$CookieConsentPolicy=0:1");
    
                var raw = JSON.stringify(subData);
    
                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
                };
    
                fetch(`https://cunning-badger-h123en-dev-ed.my.salesforce.com/services/apexrest/accountRecord/${id}`, requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));
    
            }

        });
    
    };



  return (
    <>
    <div className='pratikChandoreWrapper'>

        <div className='QandAWrapper'>

            {renderQAndA(data)}

            <button onClick={handleSubmit}>Submit</button>

        </div>


    
    </div>


    {/* <Footer /> */}



    </>
  )
}

export default PratikChandore