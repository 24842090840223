import "./Homepage.css";
import React from 'react'

function Homepage() {
  return (
    <div className="container">
      <h1 className="heading">Under Construction...</h1>
      <p className="description">Hold tight! We're working hard to meet you soon!</p>
	  </div>
  )
}

export default Homepage