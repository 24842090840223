import './App.css';
import { Routes, Route } from 'react-router-dom';
import Homepage from './components/Homepage/Homepage';
import Check from './components/Check';
import { Login } from './components/checkFolder/Login';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import MyData from './components/checkFolder/MyData';
import TicTokClone from './components/Projects/Tic-Tok-Clone/TicTokClone';
import HeaderSection from './components/HeaderSection/HeaderSection';
import NotFoundPage from './components/Pages/NotFoundPage';
import PratikChandore from './components/PratikChandore/PratikChandore';

function App() {
  const url = window.location.href

  return (
    <div className="App">
      
      <HeaderSection url={url}/>

      <Routes>

        <Route path='/' element={<Homepage />} />

        <Route path='/check' element={<Check />} />

        <Route path='/login' element={<Login />} />

        <Route path='/projects/tic-tok-clone' element={<TicTokClone />} />

        <Route path='/nav/mydata' element={<MyData />} />

        <Route
          path='/mydata'
          element={
            <PrivateRoute>
              <MyData />
            </PrivateRoute>
          }
        />

        <Route 
          path='/poc/*'
          element={<PratikChandore />}
        />

        {/* <Route  
          path='/testAutodeployment'
          element={<PratikChandore />}
        /> */}

        {/* <Route
          path='/my-projects/tic-tok-clone'
          element={
            <PrivateRoute>
              <MyData />
            </PrivateRoute>
          }
        /> */}

        <Route
          path="*"
          element={
            <NotFoundPage />
          }
        />

      </Routes>

    </div>
  );
}

export default App;
