import { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) =>{

    const [token, setToken] = useState("");
    
    useEffect(() => {
        let tok = JSON.parse(window.localStorage.getItem('token'));
        if(tok){
            setToken(tok);
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem('token', JSON.stringify(token));
    }, [token]);


    const handleToken = (newToken)  => {
        setToken(newToken);
        // window.localStorage.setItem('token', JSON.stringify(newToken));
    };

    return(

        <AuthContext.Provider value={{token, handleToken}}>{children}</AuthContext.Provider>
    )
}