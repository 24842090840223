import { useContext, useState } from "react"
import { AuthContext } from "../../context/AuthContext"

export const Login =() => {
    const {handleToken, token} = useContext(AuthContext);

    // const {id} = useParams();

    // const id = 1;

    const [name, setName] = useState("");
    const [pass, setPass] = useState("");

    if(token){
        return (<div>
          <p>User is login now</p>
        </div>)
    }
    

    return (
        <div >
            <br /><br />
            <input type="text" placeholder="Username"
            onChange={(e) => setName(e.target.value)}/>
            <br /><br /><br />
            <input type="text" placeholder="Password"
            onChange={(e) => setPass(e.target.value)}/>
            <br /><br /><br />
            <button onClick={() => {
                fetch("https://reqres.in/api/login", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email: name, password:pass }),
                  })
                    .then((d) => d.json())
                    .then(({ token }) => {
                      handleToken(token);
                    });
        
            }}>Login</button>
        </div>
    )
}