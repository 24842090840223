import React from 'react'
import { Navbar } from "../checkFolder/Navbar"

function HeaderSection({url}) {
    // const url = window.location.href

  return (
    <>
        {
            url.includes("/projects/") ? null : <h1>PratCoder</h1>
        }
        {
            url.includes("/nav/") ? <Navbar /> : null
        }
    </>
  )
}

export default HeaderSection