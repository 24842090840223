import React , {useRef, useState, useEffect} from 'react';
import useElementOnScreen from "../../../Hooks/useElementOnScreen";
import "./Video.css";

function Video({videoUrl}) {
  const videoRef = useRef(null);
  const [playing, setPlaying ] = useState(false);

  const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3
  }

  const isVisibile = useElementOnScreen(options, videoRef);

  useEffect(() => {
    if (isVisibile) {
      if (!playing) {        
        videoRef.current.play();
        setPlaying(true)
      }
    }
    else {
      if (playing) {        
        videoRef.current.pause();
        setPlaying(false)
      }
    }
  }, [isVisibile])

  const handleVideoPress = () => {
    if(playing){
        videoRef.current.pause();
        setPlaying(false);
    }else{
        videoRef.current.play();
        setPlaying(true);
    }
  }

  return (
    <div className='video'>
        <div className='video__wrap'>
            <video
            onClick={handleVideoPress}
            ref={videoRef}
            loop
            className='video__player'
            src={videoUrl}
            />
        </div>
    </div>
  )
}

export default Video