import "./NotFoundPage.css";
import React from 'react';
// import resultNotFount from "../../../public/resultNotFount.jpg";

function NotFoundPage() {
  return (
    <div className='wrapper'>
        <h2>404 Page not found</h2>
        <image src="/images/resultNotFount.jpg" />
    </div>
  )
}

export default NotFoundPage